import React from "react";
import { Seo } from "../components/seo";
import Layout from "../hoc/Layout/Layout";

import PolicyContent from "../components/PolicyContent/PolicyContent";

// markup
const IndexPage = () => {

	return (
		<Layout>
			<Seo title="Integritetspolicy - Putso" />
			<PolicyContent />
		</Layout>
	);
};

export default IndexPage;
